.border {
  &-nurture {
    &-pink, &-pink:hover {
      border-color: $nurture-pink;
    }

    &-green, &-green:hover {
      border-color: $nurture-green;
    }

    &-green-variant, &-green-variant:hover {
      border-color: $nurture-green-variant;
    }

    &-light-green-variant, &-light-green-variant:hover {
      border-color: $nurture-light-green-variant;
    }

    &-orange, &-orange:hover {
      border-color: $nurture-orange;

      &-light, &-light:hover {
        border-color: #FDEBEF !important;
      }
    }

    &-purple, &-purple:hover {
      border-color: $nurture-purple;
    }
  }
}
