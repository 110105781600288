@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.font {
  &-size {
    &-10 {
      font-size: 10px;
    }

    &-12 {
      font-size: 12px;
    }

    &-13 {
      font-size: 13px;
    }

    &-14 {
      font-size: 14px;
    }

    &-15 {
      font-size: 15px;
    }

    &-16 {
      font-size: 16px;
    }

    &-17 {
      font-size: 17px;
    }

    &-18 {
      font-size: 18px;
    }

    &-20 {
      font-size: 20px;
    }

    &-22 {
      font-size: 22px;
    }

    &-24 {
      font-size: 24px;
    }

    &-32 {
      font-size: 24px;
    }
  }

  &-weight {
    &-100 {
      font-weight: 100;
    }

    &-200 {
      font-weight: 200;
    }

    &-300 {
      font-weight: 300;
    }

    &-400 {
      font-weight: 400;
    }

    &-500 {
      font-weight: 500;
    }

    &-600 {
      font-weight: 600;
    }

    &-700 {
      font-weight: 700;
    }

    &-800 {
      font-weight: 800;
    }

    &-900 {
      font-weight: 900;
    }
  }
}

.small-screen-text {
  @include media-breakpoint-down(sm) {
    font-size: calc(1.0rem + .6vw) !important;
  }
}