@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "scss/variables";

@keyframes slideDown {
  0% {
    transform: translateY(-100%); /* Start above the view */
    opacity: 0;
  }
  30% {
    transform: translateY(0);
    opacity: 1;
  }
  45% {
    transform: translateY(-10%);
  }
  60% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(0); /* End at final position */
    opacity: 1;
  }
}

.DynamicAlerts {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    width: 100%;
    z-index: 9999;
    box-shadow: 0px 4px 6px -2px grey;
    margin-top: 1rem;
    margin-bottom: 1rem;
    animation: slideDown 0.5s ease-in-out;


    @include media-breakpoint-down(sm) {
      height: 100%;
    }
  }
}