.table-sortable {
  thead {
    tr {
      th.sortable {
        cursor: pointer;
        position: relative;

        &:after {
          content: ' ';
          position: absolute;
          height: 0;
          width: 0;
          right: 5px;
          top: 16px;

          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #ccc;
          border-bottom: 0px solid transparent;
        }

        &:hover:after {
          border-top: 5px solid #888;
        }

        &.asc:after {
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 0px solid transparent;
          border-bottom: 5px solid #333;
        }

        &.asc:hover:after {
          border-bottom: 5px solid #888;
        }

        &.desc:after {
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #333;
          border-bottom: 5px solid transparent;
        }
      }
    }
  }
}

table {
  .selectable {
    opacity: 0.6;
    cursor: pointer;
    position: relative;

    &.selected, &:hover {
      opacity: 1;
      background-color: rgba(53, 41, 75, 0.4);
    }

    .form-check {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .selectable-student {
    opacity: 0.6;

    &.selected {
      opacity: 1;
      background-color: rgba(53, 41, 75, 0.4);
    }
  }
}

.table-nurture-striped {
  th {
    background-color: $base-background;
  }

  tr:nth-child(even) {
    td {
      background-color: $base-background;
    }
  }

  tr:nth-child(odd) {
    td {
      background-color: #D2DDEC !important;
    }
  }
}

