@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

.gradient-background {
  background: linear-gradient(10deg, #4300B1, #EA3DCE);
  border-radius: 10px;
  color: white;
}

h2.AssignmentHeader {
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;

  a {
    text-decoration: none;
    color: #1e3149;
  }
}

.small-texts {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.AssessmentSubmissionTab {
  display: flex;
  justify-content: space-between;
  background-color: #f5f3ff;
  padding: 5px;
  border-radius: 12px;

  .assessment, .submissions {
    flex: 1;
    text-align: center;
    padding: 10px 15px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    background-color: #F9F5FF;
    color: #667085;

    &.active {
      background-color: #E9D7FE;
      color: black;
    }
  }
}

.merriweather-font {
  font-family: "Merriweather", serif;
}