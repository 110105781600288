.AssignmentTemplateView {
  //min-height: 100vh;

  .main-body {
    margin-bottom: 2rem;
  }

  .no-feedback {
    position: relative;
    width: 100%;
    min-height: 300px;


    .objectives {
      position: absolute;
    }

    .overlay {
      color: white;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(77, 89, 106, 0.85);
      z-index: 50;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }
  }


  .footer {
    left: 0;
    width: 100%;
    margin-bottom: 0;
    position: fixed;
    bottom: 0;
    z-index: 2000000001;
  }
}
