.heading-text {
  color: #2C7BE5;
}

.submission-type-option {
  width: 100%;
}

.right-align {
  right: 60;
}

.highlight {
  border-width: medium !important;
  background-color: #EDF2F9 !important;
}

.point-size {
  font-size: 13px;
}

.vector-style {
  margin-top: 3px;
  margin-right: 2px;
}