@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.assignment-templates-modal {
  .modal-header {
    border-bottom: none;
    color: #2C7BE5;

    .modal-title {
      width: 100%;
    }

    .copy-icon {
      width: 32px;
      height: 32px;
    }

    .subtitle {
      font-size: 12px;
      font-weight: 100;
    }
  }

  .modal-body {
    .template-summary {
      .title {
        color: #283E59;
      }

      .space-section {
        background-color: #e7e9eb;
        color: #6E84A3;

        .code {
          font-weight: bold;
        }
      }
    }

    .template-name {
      .input-group-text, input {
        background-color: #ffffff;
        border-radius: 6px;
        border-color: #E3EBF6;
      }

      input {
        border-left: none;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .feedback-alert {
      .form-check-input {
        width: 1.4em;
        height: 1.4em;
      }

      .form-check-label {
        font-size: 16px;
        margin-left: 0.8em;
        margin-top: 0.22em;
      }
    }

    .question {
      font-weight: 400;
      line-height: 30px;
      color: #6E6E6E;

    }

    .item-box {
      border: 1.5px solid #D5D5D5;
      border-radius: 10px;
      transition: all 0.1s;

      &.active, &:hover {
        border: 2px solid #A6C5F7;
        background-color: rgba(81, 168, 248, 0.1);
      }

      img {
        width: 70px;
        height: 70px;

        @include media-breakpoint-up(sm) {
          width: 90px;
          height: 90px;
        }

        @include media-breakpoint-up(lg) {
          width: 120px;
          height: 120px;
        }
      }

      &-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #1F2D3D;
      }

      &-subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #818181;
      }
    }

    .disabled {
      pointer-events: none;
      cursor: not-allowed !important;

      .item-box-title, .item-box-subtitle {
        color: #bbbbbb;
      }
    }

    .info {
      &-text {
        font-weight: 400;
        font-size: 12px;
        color: #6E84A3;
      }
    }
  }
}

.assignment-templates-card {
  .grey-text {
    color: #6E84A3;
  }

  .dark-grey-text {
    color: #6E84A3;
  }

  .link-text {
    color: #2C7BE5;
    cursor: pointer;
    text-decoration: underline;
  }

  .school-logo {
    width: 48.42px;
    height: 48.42px;
  }

  .extra-content {
    transition: all 0.2s;

    &-hide {
      display: none;
    }
  }

  .card-header {
    border-bottom: 2px solid #EDF2F9;;
  }
}

.hidden-text {
  width: 3px;
  opacity: 0;
  padding: 0;
  border: none;
}

.search-filter {
  &:focus {
    box-shadow: none !important;
  }
}