.filter-dropdown {
  .accordion {
    &-button {
      color: #1F2D3D;
      padding-top: 8px;
      padding-bottom: 8px;

      &:focus {
        box-shadow: none !important;
      }

      &:not(.collapsed) {
        box-shadow: none;
        color: #1F2D3D;
      }
    }
  }

  .list-group {
    &-item {
      color: #1F2D3D;

      &.disabled {
        color: #A6A6A6;
      }
    }
  }
}

.sort-filter {
  min-width: 300px;
}