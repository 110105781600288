.RubricsDropdown {
  .dropdown-container {
    position: relative;
  }

  .dropdown-button {
    width: 100%;
    padding: 10px;
    background-color: #f5f5f5;
    border: 2px solid #dcdcdc;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    transition: border 0.3s ease;
  }

  .dropdown-button:hover {
    border-color: #6f42c1;
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 2px solid #dcdcdc;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 250px;
    overflow-y: auto;
    z-index: 1000;
    display: none;
  }

  .dropdown-menu.open {
    display: block;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .dropdown-item:hover,
  .dropdown-item.highlighted {
    background-color: #f0f0f0;
  }

  .item-icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .item-icon .item-number {
    background-color: #ffdf6e;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-left: 6px;
  }

  .item-label {
    font-size: 14px;
    //display: flex;
    //flex-direction: column;
  }
}
