// Base Theme
$nurture-pink: #FFA38F;
$nurture-orange: #ef674a;
$nurture-green: #6CBDBF;
$nurture-green-variant: #6CDDC9;
$nurture-light-green-variant: #E6FCF3;
$nurture-purple: #35294b;
$nurture-purple-light: #b7a9d1;
$nurture-blue: #2C7BE5;

// Input Fields colors
$input-background-color: #F9FBFD;
$input-border-color: #B1C2D9;
$input-border: 1.5px solid #B1C2D9;

$base-background: #EDF2F9;
$white: #ffffff;

//
// Color system
//

// stylelint-disable
$white: #FFFFFF !default;
$gray-100: #F9FBFD !default;
$gray-200: #EDF2F9 !default;
$gray-300: #E3EBF6 !default;
$gray-400: #D2DDEC !default;
$gray-500: #B1C2D9 !default;
$gray-600: #95AAC9 !default;
$gray-700: #6E84A3 !default;
$gray-800: #3B506C !default;
$gray-900: #283E59 !default;
$black: #12263F !default;
$blue: #2C7BE5 !default;
$indigo: #727cf5 !default; // NOT USED
$purple: #6b5eae !default;
$pink: #ff679b !default; // NOT USED
$red: #E63757 !default;
$orange: #fd7e14 !default; // NOT USED
$yellow: #F6C343 !default;
$green: #2ea44f !default;
$teal: #02a8b5 !default; // NOT USED
$cyan: #39afd1 !default; // NOT USED

$primary: $blue !default;
$secondary: $gray-700 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $black !default;

$theme-colors: () !default;
$theme-colors: map-merge(("primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark,
        "white": $white), $theme-colors);
// stylelint-enable

$lighter: $gray-100 !default;
$text-muted: $gray-600 !default;

$spacer: 1.5rem !default;
$spacers: () !default;
$spacers: map-merge((0: 0,
        1: ($spacer * .125), // 3px
        2: ($spacer * .25), // 6px
        3: ($spacer * .5), // 12px
        4: $spacer, // 24px
        5: ($spacer * 1.5), // 36px
        6: ($spacer * 3), // 72px
        7: ($spacer * 4.5), // 108px
        8: ($spacer * 9) // 216px
), $spacers);
$line-height-lg: 1.5 !default;
$line-height-sm: 1.75 !default;

$link-color: theme-color("primary") !default;
// $link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: none !default;

$font-size-base: 0.9375rem !default; // 15px
$font-size-lg: ($font-size-base * 1.133333333334) !default; // 17px
$font-size-sm: ($font-size-base * .866666667) !default; // 13px
$font-size-xs: ($font-size-base * .6666666667) !default; // 10px

$headings-letter-spacing: -.02em !default;

$display-letter-spacing: -.02em !default;

$font-weight-light: 400 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;

$line-height-base: 1.5 !default;

$border-width: 1px !default;
$border-color: $gray-300 !default;

$border-radius: 0.375rem !default;
$border-radius-lg: 0.5rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-xs: .1875rem !default;

$caret-width: .25em !default;
$card-margin-bottom: $spacer !default;
$card-spacer-x-sm: 1rem !default;
$card-border-color: $gray-200 !default;
$border-width: 1px !default;
$table-head-bg: $gray-100 !default;

$card-outline-color: $card-border-color !default;
$card-box-shadow: 0 .75rem 1.5rem transparentize($black, .97) !default;

$card-header-height: 60px !default;

$table-cell-padding: 1rem !default;
$table-cell-padding-sm: 1rem !default;

$table-accent-bg: $gray-100 !default;
$table-hover-bg: $gray-100 !default;
$table-active-bg: $gray-100 !default;

$table-border-color: $gray-200 !default;

$table-head-bg: $gray-100 !default;
$table-head-color: $text-muted !default;

$table-striped-order: even !default;
// Cards

$card-spacer-y: 1rem !default;
$card-spacer-x: $spacer !default;
$card-border-width: $border-width !default;
$card-border-radius: $border-radius-lg !default;
$card-border-color: $gray-200 !default;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg: transparent !default;

$custom-control-cursor: pointer !default;
$custom-control-indicator-bg: $border-color !default;
$custom-control-indicator-border-width: 0 !default;
$custom-control-circle-indicator-size: 1.375rem !default;

$custom-control-circle-indicator-border-radius: 50% !default;
$custom-control-circle-indicator-icon-checked: url(escape-svg("data:image/svg+xml,<svg viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M11.3333 1L3.99996 8.33333L0.666626 5' stroke='#{$white}' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/></svg>")) !default;

$custom-control-circle-indicator-bg-size: .75rem .5625rem !default;