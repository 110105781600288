@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "scss/variables";
@import "scss/btn";
@import "scss/texts";
@import "scss/progress";
@import "scss/forms";
@import "scss/badges";
@import "scss/selectric";
@import "scss/fonts";
@import "scss/tables";
@import "scss/bg";
@import "scss/widths";
@import "scss/borders";

@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

// Modal
$modal-dialog-vertical-width: 800px !default;
$modal-card-body-max-height: 350px !default;

body {
  font-family: "Cerebri Sans", sans-serif;
  font-size: 15.5px;
  padding-top: 34px;
  margin: 0;
  font-weight: normal;
  line-height: 1.5;
  color: #12263F;
  text-align: left;
  background-color: $base-background;
}

// Spacing
.px-custom {
  padding-left: 38px;
  padding-right: 38px;
}

.mx-custom {
  margin-left: 38px;
  margin-right: 38px;
}

.my-custom {
  margin: 27px 0px;
}

.text-muted {
  color: #95AAC9 !important;
}

.required {
  color: red;
}

.container {
  @include media-breakpoint-down(sm) {
    padding-left: 5px;
    padding-right: 5px;
  }

  @include media-breakpoint-between(sm, xxl) {
    max-width: 96%;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 1320px
  }
}

input::placeholder,
textarea::placeholder {
  color: lightgray;
  font-size: 14px;
}

.switch {
  display: flex;
  align-items: center;

  input {
    font-size: 26px;
  }
}

hr {
  margin: 32px 0;
}

.preview-button {
  padding: 1px 11px;
  font-size: 13px;
  color: black;
  border: #ccc solid .5px;

  &:hover {
    background-color: honeydew;
    opacity: 0.29;
    color: black;
  }
}

.form-check-input[type=checkbox]:focus {
  box-shadow: 0 0 0 0;
}

.card-columns {
  @include media-breakpoint-only(sm) {
    column-count: 1;
  }

  @include media-breakpoint-only(md) {
    column-count: 1;
  }

  @include media-breakpoint-only(lg) {
    column-count: 2;
  }

  @include media-breakpoint-only(xl) {
    column-count: 2;
  }

  .card {
    display: inline-block;
    width: 100%
  }

}

.submission-modal {
  max-width: 100% !important;
}

.modal-dialog-vertical {
  max-width: $modal-dialog-vertical-width;
  //width: 800px;
  height: auto;
}

#assignmentModalBody, #rubricsGradingModalBody {
  background: #f8f9fb;

  @include media-breakpoint-down(sm) {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}

#attachmentPreviewModal,
#assignmentModal {
  .modal-dialog-vertical {
    height: 100%;
  }
}

.section-header-title {
  font-weight: 600;
  line-height: 1.1;

  &-large {
    font-size: 20px;
    font-weight: 500;
  }
}

.modal-dialog {

  // When fading in the modal, animate it to slide down
  .modal.fade & {
    transform: translate(0, -150px);
  }

  .modal.show & {
    transform: translate(0, 0);
  }
}

.modal-header .close {
  margin: -1.5rem -1.5rem -1.5rem auto;
}


//
// Dashkit ===================================
//

// Modal dialog vertical
//
// Creates a vertically aligned version of the modal dialog

.modal-dialog-vertical {
  height: 100%;
  max-width: $modal-dialog-vertical-width;
  margin: 0;

  .modal-content {
    height: inherit;
    // border-width: 0 $modal-content-border-width 0 0;
    border-radius: 0;
  }

  .modal-header {
    border-radius: inherit;
  }

  .modal-body {
    height: inherit;
    overflow-y: auto;
  }
}

.modal.fade .modal-dialog-vertical {
  transform: translateX(-100%);
}

.modal.show .modal-dialog-vertical {
  transform: translateX(0);
}

// Positioning

.modal.fixed-right {
  padding-right: 0 !important;
}

.modal.fixed-right .modal-dialog-vertical {
  margin-left: auto;
}

.modal.fixed-right.fade .modal-dialog-vertical {
  transform: translateX(100%);
}

.modal.fixed-right.show .modal-dialog-vertical {
  transform: translateX(0);
}

// Modal card

.modal-card {
  margin-bottom: 0;

  .card-body {
    max-height: $modal-card-body-max-height;
    overflow-y: auto;
  }
}



.custom-badge {
  span {
    font-size: 12px;
    padding: 2px 4px;
  }
}

.badge-soft-primary {
  color: #2C7BE5;
  background-color: #d5e5fa;
}

.badge-warning {
  color: #283E59;
  background-color: #F6C343;
}

.badge-soft-success {
  color: #2ea44f;
  background-color: #d5eddc;
}

.badge-soft-dark {
  color: #12263F;
  background-color: #d0d4d9;
}

.badge-soft-danger {
  color: #E63757;
  background-color: #fad7dd;
}

.badge-secondary-soft {
  color: #6E84A3;
  background-color: #eef0f4;
}

.badge-soft-info {
  color: #39afd1;
  background-color: #d7eff6;
}

.bg-secondary-soft {
  background-color: #eef0f4 !important;
}

.text-secondary {
  color: #6E84A3 !important;
}

.avatar-xs {
  width: 1.625rem;
  height: 1.625rem;
  font-size: 0.5416666667rem;
}

.font-size-xs {
  font-size: 10px;
}

.alert-light {
  color: #283E59;
  background-color: #edf2f9;
  border-color: #edf2f9;
}

.header-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #E3EBF6;
}

.border-top {
  border-top: 1px solid #E3EBF6;
  width: 100%;
}

input::placeholder {
  color: #95AAC9;
}

.small-img {
  height: 20px;
  width: 20px;
}

.custom-alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1.5rem;
  border: 1px solid transparent;
  border-radius: 0.375rem;
}

.alert-light {
  color: #283E59;
  background-color: #edf2f9;
  border-color: #edf2f9;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B1C2D9 !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #B1C2D9 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #B1C2D9 !important;
}

//Cards

.card {
  margin-bottom: 1.5rem;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  background-clip: border-box;
  border: 1px solid #EDF2F9;
  border-radius: 0.5rem;

  .subtext {
    font-size: 15px;
  }

  .mid-big {
    font-size: 17px;
  }

  &-footer {
    padding: 1rem 1.5rem;
    background-color: transparent;
    border-top: 1px solid #EDF2F9;
  }

  &-header {
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 1px solid #EDF2F9;

    .big-text {
      font-size: 1.5rem;
      font-weight: 500 !important;
    }
  }
}

.cursor {
  &-pointer {
    cursor: pointer !important;
  }

  &-not-allowed {
    cursor: not-allowed;
  }

  &-move {
    cursor: move;
  }
}

.pointer-events {
  &-none {
    pointer-events: none;
    opacity: 0.8;
  }
}

//
// comment.scss
// Dashkit component
//

// Comment
//
// General styles

.comment {
  margin-bottom: 1rem;
}

.comment-body {
  display: inline-block;
  padding: 1rem 1.25rem;
  background-color: #F9FBFD;
  border-radius: 0.5rem;
}

.comment-time {
  display: block;
  margin-bottom: .5625rem;
  font-size: 10px;
  color: #95AAC9;
}

.comment-text {
  font-size: 13px;
}

.comment-text:last-child {
  margin-bottom: 0;
}

//
// Dashkit ===================================
//

.progress-sm {
  height: 0.25rem;
}

// Logo
//
.nurture-logo {
  max-width: 200px;
}

// Generic Border none
.border-none {
  border: none;
}

.blue-background {
  background: $nurture-purple;
  color: white;
}

.circle {
  border-radius: 50%;
  width: 78px;
  height: 78px;
}

.small-div {
  border-radius: 10px;
  border: 1px solid #E3EBF6;
}

.small-text {
  font-size: 10px;
}

.product-dark-btn {
  display: inline-block;
  color: #3B516C;
  // background-color: transparent;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  border-color: #3B516C;

  &:active,
  &:hover,
  &:focus {
    color: #FFFFFF;
    background-color: #3B516C;
    border-color: #3B516C;
  }
}

.product-dark-color {
  background-color: #3B516C;
  color: #FFFFFF;

  &:disabled,
  &.disabled {
    background-color: #3B516C !important;
    color: #FFFFFF;
  }
}

// Disable input type number arrows on submission review form
  /* Firefox */
.student-grade {
  -moz-appearance: textfield;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// Snackbar Provider
.z-alert {
  z-index: 2000000900 !important;
}

// filestack modal, reduce z-index property so that the snackbar alert can be on top of it
.fsp-picker {
  z-index: 2000000800 !important;
}

.intercom-lightweight-app {
  z-index: 2000000600 !important;
}

.row {
  @include media-breakpoint-down(sm) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  &>* {
    @include media-breakpoint-down(sm) {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }
}

// Range slider
.range-slider {
  &.single-thumb {
    .range-slider__thumb[data-disabled] {
      display: none;
    }

    .range-slider__range {
      left: 0 !important;
    }
  }
}
.silent-card {
  box-shadow: none;
  margin-bottom: 0;
  padding: 0;
  border: none;

  .card {
    box-shadow: none;
    margin-bottom: 0;
    padding: 0;
    border: none;
  }

  .px-custom {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.no-icon-accordion-button::after {
  opacity: 0;
}

hr.light-divider {
  margin: 16px 0;
  border-top: 1px solid #96a1ac;
}

.merriweather-font {
  font-family: "Merriweather", serif;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.infinite-scroll-component__outerdiv {
  overflow-x: hidden !important;
}

.SubmissionType-dropdown-menu {
  width: 350px !important;

  @include media-breakpoint-up(sm) {
    width: 420px !important;
  }

  @include media-breakpoint-up(md) {
    width: 500px !important;
  }

  @include media-breakpoint-up(lg) {
    width: 700px !important;
  }
}
