$base-size: 20px;

.emoji {
  display: inline-block;

  &-high, &-happy {
    &:hover, &.active {
      background-image: url('../../../assets/images/emoji_icons/high.svg') !important;
      background-size: cover;
    }
  }

  &-low, &-disappointed {
    &:hover, &.active {
      background-image: url('../../../assets/images/emoji_icons/low.svg') !important;
      background-size: cover;
    }
  }

  &-neutral {
    &:hover, &.active {
      background-image: url('../../../assets/images/emoji_icons/neutral.svg') !important;
      background-size: cover;
    }
  }


  // Sizes

  &-xs {
    width: $base-size * 0.8;
    height: $base-size * 0.8;
  }

  &-sm {
    width: $base-size;
    height: $base-size;
  }

  &-md {
    width: $base-size * 1.2;
    height: $base-size * 1.2;
  }

  &-lg {
    width: $base-size * 1.5;
    height: $base-size * 1.5;
  }

  &-xl {
    width: $base-size * 2;
    height: $base-size * 2;
  }
}