.bg {
  &-nurture {
    &-pink {
      background-color: $nurture-pink;
    }

    &-green {
      background-color: $nurture-green;
    }

    &-green-variant {
      background-color: $nurture-green-variant;
    }

    &-light-green-variant {
      background-color: $nurture-light-green-variant;
    }

    &-orange {
      background-color: $nurture-orange;

      &-light {
        background-color: #FDEBEF !important;
      }
    }

    &-purple {
      background-color: $nurture-purple;
    }

    &-navy-blue {
      background-color: #3B516C;
    }

    &-light {
      background-color: #f8f9fb;
    }

    &-light-grey {
      background-color: #EDF2F9;
    }
  }
}
