.empty-container {
  height: calc(100vh - 100px);

  .login-image-container {
    height: 65%;
    max-width: 80%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
};