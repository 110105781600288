.confidence-text {
  font-size: 13px;
}

.confidence {
  background-color: #EDF2F9;
  padding-left: 10px;
  padding-right: 7px;
}

.paper-note {
  margin-left: 15px;
}

.submit-checkbox {
  margin-left: 15px;
}

.student-notes {
  font-size: 13px;
}

.description {
  font-size: 12px;
  color: #6E84A3;
}

.note {
  font-size: 13px;
  margin-left: 12px;
  margin-top: 3px;
}

.disclaimer {
  background-color: #FFFAD4;

  .icon {
    margin-top: 12px;
    margin-bottom: 3px;
    margin-left: 6px;
  }

  .notice {
    font-size: 12px;
    color: #6E84A3;
    margin-left: 10px;
    margin-top: 7px;
    margin-bottom: 7px;
  }
}