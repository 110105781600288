@import "scss/variables";

.card-table {
  margin-bottom: 0;
}

.card-table thead th {
  border-top-width: 0;
}

.card-table thead th,
.card-table tbody td {

  &:first-child {
    padding-left: $card-spacer-x !important;
  }

  &:last-child {
    padding-right: $card-spacer-x !important;
    padding-right: 1.5rem !important;
    padding-bottom: 21px;
    padding-top: 21px;
  }
}

.card > .card-table:first-child,
.card > .table-responsive:first-child > .card-table {

  > thead, > tbody, > tfoot {

    &:first-child {

      > tr:first-child {

        > th, > td {

          &:first-child {
            border-top-left-radius: $card-border-radius;
          }

          &:last-child {
            border-top-right-radius: $card-border-radius;
          }
        }
      }
    }
  }
}

// 
// tables.scss
// Extended from Bootstrap
//

// 
// Bootstrap Overrides =====================================
//

.table {

  thead th {
    //background-color: $table-head-bg;
    text-transform: uppercase;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    letter-spacing: .08em;
    //color: $table-head-color;
  }

  thead th, tbody th, tbody td {
    vertical-align: middle;
  }
  .table-checkbox {
    border: none;
  }
  tbody {
    border-top: transparent !important;
  }
}

.table-sm {
  font-size: $font-size-sm;

  thead th {
    font-size: $font-size-xs;
  }
}

//
// Dashkit =====================================
//

// No wrap
//
// Prevents table content from wrapping to the next line

.table-nowrap {

  th, td {
    white-space: nowrap;
  }
}

// Sort
//
// Adds sorting icons

.table [data-sort] {
  white-space: nowrap;

  &::after {
    content: str-replace(url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='#{$text-muted}'/></svg>"), "#", "%23");
    margin-left: .25rem;
  }
}


// Table checkbox

.table-checkbox {
  min-height: 0;
}

.table-checkbox .custom-control-label::before,
.table-checkbox .custom-control-label::after {
  top: 50%;
  transform: translateY(-50%);
}


// Links

.text-reset {
  text-decoration: none;
  &:hover {
    columns: $link-color !important;
  }
}


.card {
  .table-card-footer {
    border-top: none;
    background-color: inherit;
  }
  .search {
    border: none;
    background-color: transparent;
    margin-top: 5px;
    margin-left: -4px;
  }
}

