.badges {
  span {
    font-size: 12px;
    padding: 2px;
  }

  .badge-soft-primary {
    color: #2C7BE5;
    background-color: #d5e5fa;
  }

  .badge-warning {
    color: #283E59;
    background-color: #F6C343;
  }

  .badge-soft-success {
    color: #2ea44f;
    background-color: #d5eddc;
  }

  .badge-soft-dark {
    color: #12263F;
    background-color: #d0d4d9;
  }
}

.badge-nurture-purple {
  color: #ffffff;
  background-color: $nurture-purple;
}

.badge {
  display: inline-block;
  padding: 0.33em 0.5em;
  font-size: 76%;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
}