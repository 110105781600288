.AssignmentCard {
  border: 1px solid #D0D5DD !important;
  box-shadow: none !important;
}


.name {
  font-weight: 600;
  line-height: 1.1;
  color: black;
  text-decoration: none;

  &:hover {
    color: #2C7BE5;
  }
}

.small-text {
  font-size: 13px;
  font-weight: 400;
}

.assignment-badge-fixed-width{
  min-width: 35%;
}

.metrics-value {
  color: #101828;
}

.metric-label {
  color: #667085;
}


