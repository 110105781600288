@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.HeaderNew {
  background-color: #ffffff;
  border-radius: 0.25rem;
  //padding: 1rem;

  &-container {
    width: 100%;
    padding: 1rem;

    @include media-breakpoint-up(lg) {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .left {
      @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        flex-grow: 1;

        & > * {
          margin-left: 1rem;
          margin-right: 1rem;
        }
      }
    }

    .right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      > *:not(:last-child) {
        margin-right: 1rem;
      }

      .assessment-button {
        background-color: #7F56D9;
        color: #ffffff;

        &:hover {
          background-color: #7F56D9;
        }
      }

      .settings {
        cursor: pointer;
        border-radius: 9999px;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        padding: 0.25rem;

        &:hover {
          padding: 0.25rem;
          background-color: #E9D7FE;
        }
      }
    }
  }

  &-nav {
    cursor: pointer;
    color: #667085;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      margin-right: 1rem;
    }

    &.active {
      color: #182230;
      background-color: #F4F3FF;
      padding: 0.75rem;
      border-radius: 0.25rem;
    }
  }
}