.avatars.avatars-md {
  display: inline-flex;
  .avatar {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 54px;
    height: 54px;
  }
  
  .avatar:not(:first-child) {
    margin-left: -20px;
  }

  .avatar:first-child {
    margin-left: 0px;
  }
  
  .avatar img {
    width: 100%;
    display: block;
  }
}

.avatars.avatars-xs {
  .avatar {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 25px;
    height: 25px;
  }

  .avatar:not(:first-child) {
    margin-left: -7px;
  }

  .avatar:first-child {
    margin-left: 0px;
  }

  .avatar img {
    width: 100%;
    display: block;
  }
}

.avatars.avatars-sm {
  .avatar {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 38px;
    height: 38px;
  }

  .avatar:not(:first-child) {
    margin-left: -10px;
  }

  .avatar:first-child {
    margin-left: 0px;
  }

  .avatar img {
    width: 100%;
    display: block;
  }
}

