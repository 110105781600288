.card {
  .card-header {
    background-color: #ffffff;

    .card-title {
      font-weight: bold;
      padding: 10px 0;
    }
  }
}

form.assignment-form .AssignAndSchedule {
  .accordion {
    &-item {
      border-radius: 10px !important;
    }

    &-header {
      padding: 0 31px;
      border: 1.5px solid #B1C2D9;
      border-radius: 10px;
      background-color: #F9FBFD;

      &>div {
        padding: 36px 0;
      }

      button {
        width: 20px;
        border: none;

        &:focus, &:not(.collapsed) {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

.block {
  display: flex;
}

.markdown {
  height: fit-content
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
