.text {
  &-nurture {
    &-pink {
      color: $nurture-pink;
    }

    &-green {
      color: $nurture-green;
    }

    &-orange {
      color: $nurture-orange;
    }

    &-purple {
      color: $nurture-purple;
    }

    &-blue {
      color: $nurture-blue;
    }
  }

  &-grey {
    color: #6E84A3;
  }
}