.form-control, .form-select, .input-group-text {
  background: $input-background-color;
  border: $input-border;
  border-radius: 7px;
  padding-top: 8px;
  padding-bottom: 8px;

  &.error, &.error:focus {
    border-color: $danger;
  }

  &:focus {
    background: $input-background-color;
    border: 1.5px solid $nurture-green;
    border-radius: 7px;
    box-shadow: none;
    outline: none;

    & + .input-group-text {
      background: $input-background-color;
      border: 1.5px solid $nurture-green;
      border-radius: 7px;
      box-shadow: none;
      outline: none;
    }
  }

  &.search:focus {
    border: none;
  }
}

#rubrics-curriculum-boundary-switch:checked, #rubrics-language-support-switch:checked {
  background-color: $nurture-green;
  border-color: $nurture-green;
}

.form-check-input:checked {
  background-color: $nurture-purple;
  border-color: $nurture-purple;
}

.form-check-input:focus {
  border-color: rgba(0,0,0,.25);
}

.input-group-flush {
  >.form-control {
    padding-top: 0;
    padding-bottom: 0;
    height: 37px;
    margin-top: 5px;
    border-color: transparent;
    background-color: transparent;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-size: inherit;
    }
  }

  .input-group.input-group-merge {

    >.form-control {
      border-radius: $input-border-radius;

      &:focus {
        box-shadow: none;

        ~[class*="input-group"] {

          >.input-group-text {
            border-color: $input-focus-border-color;
          }
        }
      }
    }
  }

  .form-control {
    &:focus {
      box-shadow: none;
      border-color: #ced4da;
    ;
    }
  }

  >.input-group-prepend,
  >.input-group-append {

    >.input-group-text {
      padding: 0;
      border-width: 0;
      background-color: transparent;
    }
  }
}

.form-select {
  &.default {
    appearance: auto !important;
  }
}

// custom Editor

.EasyMDEContainer {
  border: $input-border;
  border-radius: 7px;
  background: #F9FBFD;

  .editor-toolbar {
    border: none;
  }

  .codeMirror {
    border: none;
    //border-top: 1px solid #ced4da;
    background: inherit;
    border-radius: 7px;

    &-placeholder {
      color: #476691 !important;
      font-size: 14px !important;
    }
  }
}

.error {
  .EasyMDEContainer {
    border-color: $danger;
  }
}