.assignment-header.Badge {
  font-size: 16px;
}

.badge.Badge {
  font-weight: normal;

  &-soft-primary {
    color: #2C7BE5;
    background-color: #d5e5fa;
  }

  &-light {
    color: #283E59;
    background-color: #EDF2F9;
  }

  &-soft-dark {
    color: #12263F;
    background-color: #d0d4d9;
  }

  &-soft-success {
    color: #2ea44f;
    background-color: #d5eddc;
  }

  &-warning {
    color: #283E59;
    background-color: #F6C343;
  }

  &-soft-danger {
    color: #E63757;
    background-color: #fad7dd;
  }
}