.progress {
  &-nurture {
    &-pink {
      background-color: $nurture-pink;
    }

    &-green {
      background-color: $nurture-green;
    }

    &-orange {
      background-color: $nurture-orange;
    }

    &-purple {
      background-color: $nurture-purple;
    }
  }
}