@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.embedded-content {
  width: 100%;
  height: 220px;

  @include media-breakpoint-up(sm) {
    height: 360px;
  }

  @include media-breakpoint-up(xl) {
    width: 640px;
    height: 360px;
  }
}