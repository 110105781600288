@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "scss/variables";


.nurture-ai {
  border: solid 1px $nurture-purple;
  border-radius: 7px;
  background-color: $nurture-purple;
  opacity: 0.9;
}

.feedback {
  &.negative {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    border-radius: 15px;
  }

  &.positive {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    border-radius: 15px;
  }
}

.NurtureAIAssistantCommentSuggestion {
  .chats {
    height: 700px;
    overflow: scroll;

    @include media-breakpoint-between(md, lg) {
      height: 100vh;
    }

    @include media-breakpoint-up(lg) {
      height: 100vh;
    }
  }

  .chatField-container {
    //background-color: #ffffff;
    //position: sticky;
    //bottom: 0;

    .chatField {
      position: relative;

      button {
        position: absolute;
        bottom: 15px;
        right: 10px;
      }
    }
  }
}