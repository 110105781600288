.btn {
  &-nurture {
    &-pink {
      background-color: $nurture-pink;
      color: $white;
      opacity: 0.9;

      &:hover {
        background-color: $nurture-pink;
        color: $white;
        opacity: 1;
      }

      &.disabled, &.disabled:hover {
        background-color: $nurture-pink;
        color: $white;
        opacity: 0.65;
      }
    }

    &-green {
      background-color: $nurture-green;
      color: $white;
      opacity: 0.9;

      &:hover {
        background-color: $nurture-green;
        color: $white;
        opacity: 1;
      }

      &.disabled, &.disabled:hover {
        background-color: $nurture-green;
        color: $white;
        opacity: 0.65;
      }
    }

    &-orange {
      background-color: $nurture-orange;
      color: $white;
      opacity: 0.9;

      &:hover {
        background-color: $nurture-orange;
        color: $white;
        opacity: 1;
      }

      &.disabled, &.disabled:hover {
        background-color: $nurture-orange;
        color: $white;
        opacity: 0.65;
      }
    }

    &-purple {
      background-color: $nurture-purple;
      color: $white;
      opacity: 0.9;

      &:hover {
        background-color: $nurture-purple;
        color: $white;
        opacity: 1;
      }

      &.disabled, &.disabled:hover {
        background-color: $nurture-purple;
        color: $white;
        opacity: 0.65;
      }
    }
  }

  &-outline {
    &-nurture {
      &-pink {
        color: $nurture-pink;
        background-color: $white;
        border-color: $nurture-pink;

        &:hover {
          background-color: $nurture-pink;
          color: $white;
        }
      }

      &-green {
        color: $nurture-green;
        background-color: $white;
        border-color: $nurture-green;

        &:hover {
          background-color: $nurture-green;
          color: $white;
        }
      }

      &-orange {
        color: $nurture-orange;
        background-color: $white;
        border-color: $nurture-orange;

        &:hover {
          background-color: $nurture-orange;
          color: $white;
        }
      }

      &-purple {
        color: $nurture-purple;
        background-color: $white;
        border-color: $nurture-purple;

        &:hover, &.active {
          background-color: $nurture-purple;
          color: $white;
          border-color: $nurture-purple;
        }
      }
    }
  }

  &-white.no-hover {
    border: solid 1.5px $secondary;
  }

  &-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;

    &.btn-sm {
      width: 20px;
      height: 20px;
      text-align: center;
      padding: 4px 0;
      font-size: 12px;
      line-height: 1.428571429;
      border-radius: 10px;
    }

    &.btn-lg {
      width: 50px;
      height: 50px;
      padding: 10px 16px;
      font-size: 18px;
      line-height: 1.33;
      border-radius: 25px;
    }

    &.btn-xl {
      width: 70px;
      height: 70px;
      padding: 10px 16px;
      font-size: 24px;
      line-height: 1.33;
      border-radius: 35px;
    }
  }
}

.link-btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: $nurture-purple;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  opacity: 0.9;
  border-color: $nurture-purple;

  &:hover {
    color: #FFFFFF;
    background-color: $nurture-purple;
    border-color: $nurture-purple;
    opacity: 1;
  }
}

button.button-to-link {
  background: none;
  border: none;
  color: #1a68d1;
  padding: 0;

  &:hover {
    background: none;
    text-decoration: underline;
  }
}

.btn:focus,
button:focus {
  box-shadow: 0 0 0 0;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-block {
  width: 100%;
}

.rubric-btn.disabled {
  background: #E4EBF6;
  border: 2px solid #95AAC9;
  border-radius: 7px;
  color: #95AAC9;
  font-size: 15px;
}

.new-purple-btn {
  background-color: #7F56D9;
  color: #ffffff;

  &:hover {
    background-color: #7F59B0;
    color: #ffffff;
    opacity: 0.99;
  }
}

.new-green-btn {
  background-color: #3CCB7F;
  color: #ffffff;

  &:hover {
    background-color: #3CCB7F;
    color: #ffffff;
    opacity: 0.99;
  }
}