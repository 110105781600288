//
// header.scss
// Dashkit component
//

// Header
//
// General styles

.TheAssignment {
  margin-bottom: 1.5rem;

  .fw-600 {
    font-weight: 600;
  }
}