@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.w {
  // region Auto
  &-auto {
    width: auto !important;
  }

  &-sm-auto {
    @include media-breakpoint-up(sm) {
      width: auto !important;
    }
  }

  &-md-auto {
    @include media-breakpoint-up(md) {
      width: auto !important;
    }
  }

  &-lg-auto {
    @include media-breakpoint-up(lg) {
      width: auto !important;
    }
  }

  &-xl-auto {
    @include media-breakpoint-up(xl) {
      width: auto !important;
    }
  }

  // endregion auto

  // region 25px
  &-25px {
    width: 25px !important;
  }

  &-sm-25px {
    @include media-breakpoint-up(sm) {
      width: 25px !important;
    }
  }

  &-md-25px {
    @include media-breakpoint-up(md) {
      width: 25px !important;
    }
  }

  &-lg-25px {
    @include media-breakpoint-up(lg) {
      width: 25px !important;
    }
  }

  &-xl-25px {
    @include media-breakpoint-up(xl) {
      width: 25px !important;
    }
  }

  // endregion 25px


  // region 50px
  &-50px {
    width: 50px !important;
  }

  &-sm-50px {
    @include media-breakpoint-up(sm) {
      width: 50px !important;
    }
  }

  &-md-50px {
    @include media-breakpoint-up(md) {
      width: 50px !important;
    }
  }

  &-lg-50px {
    @include media-breakpoint-up(lg) {
      width: 50px !important;
    }
  }

  &-xl-50px {
    @include media-breakpoint-up(xl) {
      width: 50px !important;
    }
  }

  // endregion 50px


  // region 75px
  &-75px {
    width: 75px !important;
  }

  &-sm-75px {
    @include media-breakpoint-up(sm) {
      width: 75px !important;
    }
  }

  &-md-75px {
    @include media-breakpoint-up(md) {
      width: 75px !important;
    }
  }

  &-lg-75px {
    @include media-breakpoint-up(lg) {
      width: 75px !important;
    }
  }

  &-xl-75px {
    @include media-breakpoint-up(xl) {
      width: 75px !important;
    }
  }

  // endregion 75px


  // region 100px
  &-100px {
    width: 100px !important;
  }

  &-sm-100px {
    @include media-breakpoint-up(sm) {
      width: 100px !important;
    }
  }

  &-md-100px {
    @include media-breakpoint-up(md) {
      width: 100px !important;
    }
  }

  &-lg-100px {
    @include media-breakpoint-up(lg) {
      width: 100px !important;
    }
  }

  &-xl-50px {
    @include media-breakpoint-up(xl) {
      width: 100px !important;
    }
  }

  // endregion 100px


  // region 150px
  &-150px {
    width: 150px !important;
  }

  &-sm-150px {
    @include media-breakpoint-up(sm) {
      width: 150px !important;
    }
  }

  &-md-150px {
    @include media-breakpoint-up(md) {
      width: 150px !important;
    }
  }

  &-lg-150px {
    @include media-breakpoint-up(lg) {
      width: 150px !important;
    }
  }

  &-xl-150px {
    @include media-breakpoint-up(xl) {
      width: 150px !important;
    }
  }

  // endregion 150px


  // region 200px
  &-200px {
    width: 200px !important;
  }

  &-sm-200px {
    @include media-breakpoint-up(sm) {
      width: 200px !important;
    }
  }

  &-md-200px {
    @include media-breakpoint-up(md) {
      width: 200px !important;
    }
  }

  &-lg-200px {
    @include media-breakpoint-up(lg) {
      width: 200px !important;
    }
  }

  &-xl-200px {
    @include media-breakpoint-up(xl) {
      width: 200px !important;
    }
  }

  // endregion 200px
}