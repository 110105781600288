.attachment.card {
  border: 1px solid #EDF2F9;
  display: inline-block;
  width: 100%;

  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    font-size: 0.9375rem;
  }
}

.annotation {
  margin-left: 12px;
  margin-top: 4px;
}