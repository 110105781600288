//
// header.scss
// Dashkit component
//

// Header
//
// General styles

.header {
  margin-bottom: 2rem;

  &-body {
    padding-top: 1.5rem;
    padding-bottom:  1.5rem;
    border-bottom: 1px solid #E3EBF6;
  }

  &.bg-dark &-body,
  &.bg-hero &-body {
    border-bottom-color: fade-out(#E3EBF6, .9);
  }

  &-footer {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &-pretitle {
    text-transform: uppercase;
    letter-spacing: .08em;
    color: #95AAC9;
  }

  &-title {
    margin-bottom: 0;
    font-size: 1.25rem;
  }

  &-subtitle {
    margin-top: 0.375rem;
    margin-bottom: 0;
    color: #95AAC9;
  }

  &-tabs {
    margin-bottom: -1.5rem;
    border-bottom-width: 0;

    .nav-link {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
}