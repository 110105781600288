.active-tab {
  border-bottom: solid 2px #2C7BE5 !important;

  > div {
    color: #1F2D3D !important;
  }
}

.HeaderMobile {
  &-nav {
    padding: 0.75rem;
    cursor: pointer;

    &.active {
      color: #182230;
      background-color: #FFFFFF;
      border-radius: 0.25rem;
    }
  }

  .HeaderMobileNav {
    position: fixed;
    margin-top: -34px;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
    z-index: 1000;
  }

  .offcanvas {
    width: 85% !important;
    background-color: #F4F3FF;
  }

  .offcanvas-backdrop.show {
    opacity: 0.8 !important;
  }

  .offcanvas-header {
    button.text-reset {
      position: relative;
      right: -16%;

      @media (max-width: 600px) {
        right: -18%;
      }

      @media (max-width: 500px) {
        right: -20%;
      }

      @media (max-width: 400px) {
        right: -22.5%;
      }

      @media (max-width: 350px) {
        right: -25%;
      }

      @media (max-width: 300px) {
        right: -25%;
      }
    }
  }
}
